import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./BigShooterStacks.module.scss"
import Row from "react-bootstrap/Row"

const data = [
  {
    name: "Stencil.js",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/stencil_js_933fbda96d.svg",
  },
  {
    name: "React Native",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_1e5446fa0c.svg",
  },
  {
    name: "Node.js",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_9c7fd4a657.svg",
  },
  {
    name: "Microservices",
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microservice_ee56e26d3b.svg",
  },
]

const BigShooterStacks = ({ strapiData }) => {
  return (
    <div className="TechStacksTabs">
      <div className={styles.techStacksBigShooter}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div className={styles.techStacksCardsContent}>
            <Row className={styles.techStacksIcon}>
              {data &&
                data?.map((e, i) => (
                  <div className={` ${styles.techIconssCard}`} key={i}>
                    <div className={styles.technologyStacksIcon}>
                      <div className={styles.techStackImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.icon}
                          alt={e?.name}
                        />
                      </div>
                      <h3>{e?.name}</h3>
                    </div>
                  </div>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}
export default BigShooterStacks
