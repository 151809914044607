// extracted by mini-css-extract-plugin
export var cardBg = "BigShooterStacks-module--cardBg--56974";
export var cards = "BigShooterStacks-module--cards--00bb3";
export var description = "BigShooterStacks-module--description--495a8";
export var heading = "BigShooterStacks-module--heading--62991";
export var infaHeading = "BigShooterStacks-module--infaHeading--450de";
export var nav = "BigShooterStacks-module--nav--4b3ae";
export var navItem = "BigShooterStacks-module--nav-item--f1beb";
export var navbarBlock = "BigShooterStacks-module--navbarBlock--66f1e";
export var tabData = "BigShooterStacks-module--tabData--8b183";
export var tech = "BigShooterStacks-module--tech--140b2";
export var techIconssCard = "BigShooterStacks-module--techIconssCard--c6026";
export var techInfrastructures = "BigShooterStacks-module--techInfrastructures--bb888";
export var techStackImg = "BigShooterStacks-module--techStackImg--ec3dc";
export var techStacksBigShooter = "BigShooterStacks-module--techStacksBigShooter--4e10e";
export var techStacksCardsContent = "BigShooterStacks-module--techStacksCardsContent--8ee66";
export var techStacksIcon = "BigShooterStacks-module--techStacksIcon--7690e";
export var technologyStacksIcon = "BigShooterStacks-module--technologyStacksIcon--6b84e";