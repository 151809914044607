import React from "react"
import * as styles from "./Objective.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Objective = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.objectiveBigShooterGolf}>
        <Container className={styles.containerBigShooterGolf}>
          <Row>
            <Col>
              <h2
                className={styles.objectiveHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div className={styles.cards}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img
                        className={styles.tick}
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/hire_Cto_Check_4d62eb15a8.svg"
                        }
                        alt={item?.title || "hire cto check mark"}
                      />
                      <div className={styles.text}>
                        <p>{item?.subTitle}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Objective
