import { Link } from "gatsby"
import React from "react"
import * as styles from "./Result.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Result = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.liveLinkBannerBigShooter}>
        <Container className={styles.containerLiveLinkResultBigShooter}>
          <Row>
            <Col>
              <h2
                className={styles.liveLinkHeadingResult}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.liveLinkDescriptionResult}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={`${styles.bannerBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to="https://apps.apple.com/ca/app/big-shooter-golf/id1558052941?platform=iphone"
                    target="_blank"
                    className={styles.btn_white2_border}
                    rel="nofollow"
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Result
