import React from "react"
import * as styles from "./Bottombanner.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import bannerBottom from "../../images/big-shooter/bigshootergolf-main-image.webp"
import Col from "react-bootstrap/Col"
const Bottombanner = () => {
  return (
    <React.Fragment>
      <div className={styles.bottomBannerBgAppWork}>
        <Container>
          <Row>
            <Col xs={12}>
              <img src={bannerBottom} alt="banner bottom" decoding="async" loading="lazy" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Bottombanner
