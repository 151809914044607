import React from "react"
import * as styles from "./ProblemsSolutions.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const ProblemsSolutions = ({ strapiData }) => {
  const challanges = [
    {
      challange: "Designing an intuitive user interface for score entry.",
    },
    {
      challange: "Implementing an accurate handicap calculation algorithm.",
    },
    {
      challange:
        "Ensuring seamless communication and synchronization between players.",
    },
    {
      challange: "Managing server load during peak usage.",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.problemsSolutionsBigShooter}>
        <Container>
          <Row>
            <Col lg={6} md={12} xs={12} sm={12} className={styles.problemsCol}>
              <h2 className={styles.problemsHeading}>Challenges</h2>
              <div className={styles.cards}>
                {challanges?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src={
                        "https://invozone-backend.s3.us-east-1.amazonaws.com/hire_Cto_Check_4d62eb15a8.svg"
                      }
                      alt={item?.challange || "hire cto check mark"}
                    />
                    <div className={styles.text}>
                      <p>{item?.challange}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col className={styles.solutionsCol}>
              <h2
                className={styles.solutionHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div className={styles.cards}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img
                        className={styles.tick}
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/white_Check_95354c03f7.svg"
                        }
                        alt={item?.title || "white check mark"
                        }
                      />
                      <div className={styles.text}>
                        <p>{item?.subTitle}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProblemsSolutions
